import { Transition } from '@headlessui/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Button, ButtonTypes } from '../components/Button';
import { Layout } from "../components/Layout";
import { LoadingSpinner } from '../components/LoadingSpinner';
import SEOHeader from "../components/SEOHeader";

declare global {
    interface Window {
        grecaptcha: any;
    }
}

const RECAPTCHA_SITE_KEY = '6LczLeMhAAAAAGhwfAISfJjVe2YNWxUbYmGouCk4';
const RECAPTCHA_SCRIPT_SRC = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;

enum ContactStates {
    NOT_STARTED = 0,
    SUBMITTED = 1,
    SUCCESS = 2,
    ERROR = 3,
}

const ContactPage = () => {
    const [contactState, setContactState] = useState(ContactStates.NOT_STARTED);

    const onSubmit = async (e) => {
        e.preventDefault();

        setContactState(ContactStates.SUBMITTED);

        window.grecaptcha.ready(async () => {
            let token;
            try {
                token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' });
            } catch (err) {
                console.error(`Error when submitting reCAPTCHA:\n${JSON.stringify(err)}`);
                setContactState(ContactStates.ERROR);
                return;
            }

            let apiResponse;
            try {
                const userName = e.target[0].value;
                const userEmail = e.target[1].value;
                const userMessage = e.target[2].value;
                const userEmailMarketingSignup = e.target[3].checked;

                apiResponse = await fetch('/api/v1/contact', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "name": userName,
                        "email": userEmail,
                        "message": userMessage,
                        userEmailMarketingSignup,
                        "g-recaptcha-response": token
                    })
                })
            } catch (err) {
                console.error(`Error when submitting contact form:\n${JSON.stringify(err)}`);
                setContactState(ContactStates.ERROR);
                return;
            }

            let apiResponseJSON;
            try {
                apiResponseJSON = await apiResponse.json();
            } catch (err) {
                console.error(`Error when transforming contact form submission response to JSON:\n${JSON.stringify(err)}`);
                setContactState(ContactStates.ERROR);
                return;
            }

            if (apiResponseJSON.captchaVerified && apiResponseJSON.mailSent) {
                setContactState(ContactStates.SUCCESS);
            } else {
                console.error(`Error when submitting contact form:\n\`captchaVerified\`: ${apiResponseJSON.captchaVerified}\n\`mailSent\`: ${apiResponseJSON.mailSent}`);
                setContactState(ContactStates.ERROR);
            }
        });
    }

    const labelClassName = `text-black/70 mb-0.5`;
    const inputClassName = `w-full text-black mb-3 p-2 text-lg rounded-sm border-2 border-emerald-900/70`;

    return (
        <Layout>
            <Helmet>
                <script src={RECAPTCHA_SCRIPT_SRC}></script>
            </Helmet>
            <SEOHeader title="Contact" description="Contact Jess and she'll respond to you via email." />
            <div className='flex flex-col w-full max-w-7xl mx-auto'>
                <Breadcrumbs
                    data={[{ title: "Home", url: "/" }, { title: "Contact Jess", url: "/contact" }]}
                />

                <div className='w-full max-w-xl mx-auto'>
                    <Transition
                        className='w-full px-4 pt-4 pb-6'
                        appear={true}
                        show={contactState === ContactStates.NOT_STARTED}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95 translate-y-4"
                        enterTo="opacity-100 scale-100 translate-y-0"
                        leave="linear duration-0"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <form className='flex flex-col w-full justify-center' onSubmit={onSubmit}>
                            <label className={labelClassName} htmlFor="userName">Your Name</label>
                            <input className={inputClassName} id="userName" placeholder='Name' type="text" required={true} />

                            <label className={labelClassName} htmlFor="userEmail">Your Email Address</label>
                            <input className={inputClassName} id="userEmail" placeholder='Email' type="email" required={true} />

                            <label className={labelClassName} htmlFor="userMessage">Your Message</label>
                            <textarea className={inputClassName} id="userMessage" placeholder='Message' required={true} />

                            <Button buttonType="submit" filled={true} buttonText='Submit' />
                        </form>

                        <div className='text-xs mt-2 text-center text-black/60'>
                            <p>This form is protected by reCAPTCHA. The Google <a className='hover:underline' href="https://policies.google.com/privacy">Privacy Policy</a> and <a className='hover:underline' href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                        </div>
                    </Transition>

                    <Transition
                        className='flex flex-col w-full px-4 pt-4 pb-6'
                        show={contactState === ContactStates.SUBMITTED}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="linear duration-0"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <LoadingSpinner className='h-16 w-16 mx-auto my-2' />
                        <p className='opacity-70 text-sm text-center'>Submitting your message...</p>
                    </Transition>

                    <Transition
                        className='flex flex-col w-full px-4 pt-4 pb-6'
                        show={contactState === ContactStates.SUCCESS}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="linear duration-0"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <h2 className="text-xl font-semibold text-center mb-1">Message Sent!</h2>
                        <p className="text-center">You will hear from me via email soon.</p>
                    </Transition>

                    <Transition
                        className='flex flex-col w-full px-4 pt-4 pb-6'
                        show={contactState === ContactStates.ERROR}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="linear duration-0"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <h2 className="text-xl font-semibold text-center mb-1">There was an error sending your message.</h2>
                        <p className="text-center">Try again later, or contact me via <a className='font-bold underline' href="https://www.instagram.com/storiesofastranger/" target="_blank" rel="noreferrer">Instagram</a>.</p>
                    </Transition>
                </div>
            </div>
        </Layout>
    )
}

export default ContactPage;
